<template>
<div class="flex w-full">
    <el-input placeholder="Username" name="username" @input="Check()" v-model="user" class="input-with-select" :disabled="loading || disabled">
        <el-button slot="append" type="button" :icon="loading?'el-icon-loading':'el-icon-refresh'" :disabled="loading || disabled" @click="Generate()"></el-button>
    </el-input>
    <p class="el-form-item__error" v-show="erorr">Ушбу фойдаланувчи тизимда мавжуд!</p>
</div>
</template>

<script>
export default {
    name: 'Generate-input',
    props: ['value', 'id', 'disabled', 'notrefresh', 'step'],
    data() {
        return {
            user: '',
            loading: false,
            first: true,
            erorr: false
        }
    },
    watch: {
        value: {
            handler(after) {
                this.user = after;
            },
            immediate: true,
        },
        user() {
            this.$emit('input', this.user);
        }
    },
    methods: {
        Generate() {
            if (this.step == 1) {
                let charactersArray = 'a-z'.split(',')
                let CharacterSet = ''
                let user = ''
                let size = 4
                if (this.first) {
                    user = 'user_' + this.id
                    this.first = false
                } else {
                    user = ''
                    if (charactersArray.indexOf('a-z') >= 0) {
                        CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
                    }
                    if (charactersArray.indexOf('A-Z') >= 0) {
                        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
                    }

                    if (charactersArray.indexOf('0-9') >= 0) {
                        CharacterSet += '0123456789'
                    }
                    if (charactersArray.indexOf('#') >= 0) {
                        CharacterSet += '![]{}()%&*$#^<>~@|'
                    }
                    for (let i = 0; i < size; i++) {
                        user += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
                    }
                    user = 'user' + '_' + this.id + user
                }
                this.loading = true
                axios.get(`/users/?search=${user}`)
                    .then(res => {
                        if (res.data.results.length < 1) {
                            this.user = user
                            this.first = true
                            this.loading = false
                        } else {
                            this.Generate()
                        }
                    })
                    .catch(() => {
                        this.loading = false
                    })
            }

        },
        Check() {
            if (this.user.length >= 2) {
                axios.get(`/users/?search=${this.user}`)
                    .then(res => {
                        if (res.data.results.length < 1) {
                            this.erorr = false
                        } else {
                            this.erorr = true
                        }
                    })
                    .catch(() => {
                        this.loading = false
                    })
            }
            else{
                 this.erorr = false
            }

        }
    },
    mounted() {
        if (this.notrefresh != '0') {
            this.Generate()
        }

    }

}
</script>
