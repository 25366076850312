<template>
<div class="flex w-full">
    <el-input name="generated_password" placeholder="Password" v-model="password" :disabled="disabled" class="input-with-select">
        <el-button id="generate_password" slot="append" type="button" :disabled="disabled" icon="el-icon-refresh" @click="Generate()"></el-button>
    </el-input>
</div>
</template>

<script>
export default {
    name: 'Generate-input',
    props: ['value', 'disabled','step'],
    computed: {
        generatedPassword() {

            return password
        },

    },
    data() {
        return {
            strengthLevel: 12,
            password: ''
        }
    },
    watch: {
        value: {
            handler(after) {
                this.password = after;
            },
            immediate: true,
        },
        password() {
            this.$emit('input', this.password);
        }
    },
    methods: {
        Generate() {
            if(this.step==1){
            let charactersArray = 'a-z'.split(',')
            let CharacterSet = ''
            let password = ''
            let size = 12
            charactersArray = 'a-z,A-Z,0-9,#'.split(',')
            if (charactersArray.indexOf('a-z') >= 0) {
                CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
            }
            if (charactersArray.indexOf('A-Z') >= 0) {
                CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
            }

            if (charactersArray.indexOf('0-9') >= 0) {
                CharacterSet += '0123456789'
            }
            if (charactersArray.indexOf('#') >= 0) {
                CharacterSet += '![]{}()%&*$#^<>~@|'
            }
            for (let i = 0; i < size; i++) {
                password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
            }
            this.password = password
            }
        }
    },
    mounted() {
        this.Generate()
    }

}
</script>
